import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Heading1 from '../../ui/Heading1';
import Heading3 from '../../ui/Heading3';

const Content = styled.article`
  max-width: 1242px;
  margin: auto;
  padding: 40px 20px;

  section {
    margin-bottom: 60px;
  }
`;

const LegalPage = () => {
  return (
    <Layout>
      <SEO title='Legal' />
      <Content>
        <Heading1>Small Door Legal Policies</Heading1>
        <Heading3>
          <Link to='/legal/terms'>Terms of Service – Small Door Inc.</Link>{' '}
          (last revised August 15, 2023)
        </Heading3>
        <Heading3>
          <Link to='/legal/privacy'>Privacy Policy – Small Door Inc.</Link>{' '}
          (last revised August 15, 2023)
        </Heading3>
      </Content>
    </Layout>
  );
};

export default LegalPage;
